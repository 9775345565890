.overall-size-matrix {
  display: grid;
  color: black !important;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 25px;
  padding: 10px 0;
  margin: auto;
}

.overall-size-matrix-cell > .data {
  font-size: 35px;
  margin-bottom: 10px;
}

.count-metrics-cell {
  display: flex;
  font-size: 10pt;
}

.count-metrics-cell > .title {
  flex-grow: 1;
}

.subtitle {
  margin: 0;
  padding: 0;
  margin-top: 15px;
  font-weight: bold;
  color: #15ab39;
  text-transform: uppercase;
}

/* infomain.jsx */
.infomain-body {
  height: 82vh;
  margin-top: 20px;
  overflow-y: scroll;
  border-radius: 5px;
}

.infomain-body::-webkit-scrollbar {
  display: none;
}

.card-my {
  background: white;
  color: black;
  margin: 10px 0;
  border-radius: 5px;
  padding: 20px;
}
/* infomain.jsx */
.filter-body {
  height: 80vh;
  overflow-y: scroll;
}

.filter-body::-webkit-scrollbar {
  display: none;
}

.filter-heading {
  margin-bottom: 10px;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.filter-heading > p,
.filter-heading > h4 {
  padding: 0;
  margin: 0;
  text-transform: none;
}

.filter-heading > .subtitle {
  font-size: 13px;
  color: rgb(139, 139, 139);
}

.global-stats-matrix {
  display: grid;
  color: black !important;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 25px;
  padding: 10px 0;
  margin: auto;
}

.global-stats-matrix-cell {
  display: flex;
  font-size: 10pt;
  flex-direction: column;
}

.global-stats-matrix-cell > .data {
  font-size: 25px;
  margin-bottom: 2px;
}

.global-stats-matrix-cell > .title {
  flex-grow: 1;
}

.meta-data {
  /* background: red; */
}

.meta-data > .title {
  font-weight: bold;
  text-transform: capitalize;
}

.meta-data > .data {
  display: inline-block;
  padding: 0;
  margin: 5px;
}
