.unauthorized {
  background: #db4640;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.unauthorized > .text {
  font-size: 15pt;
  color: white;
}
