.splash-page {
  background: url("/corona.jpg");
  min-height: 100vh;
  min-width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content > .brand {
  padding: 0;
  margin: 0;
  color: white;
  font-size: 20px;
  line-height: 18px;
  margin-left: 15px;
  margin-top: 32vh;
}

.content > .title {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  color: white;
  line-height: 150px;
  font-size: 180px;
  font-weight: 100;
  letter-spacing: 50px;
}

.loader-bottom {
  margin-top: 25vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  width: 150px;
}
