@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,900&display=swap);
.splash-page {
  background: url("/corona.jpg");
  min-height: 100vh;
  min-width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content > .brand {
  padding: 0;
  margin: 0;
  color: white;
  font-size: 20px;
  line-height: 18px;
  margin-left: 15px;
  margin-top: 32vh;
}

.content > .title {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  color: white;
  line-height: 150px;
  font-size: 180px;
  font-weight: 100;
  letter-spacing: 50px;
}

.loader-bottom {
  margin-top: 25vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  width: 150px;
}

/* home.jsx */
.home-body {
  display: flex;
  height: calc(100vh - 28px);
  width: 100vw;
}

.left-pane {
  width: 75vw;
  padding: 0px 30px;
  padding-top: 20px;
  background: #fbfbfb;
}

.right-pane {
  display: flex;
  flex-direction: column;
  width: 25vw;
  padding: 15px 30px;
  background: #dc4641;
  color: white;
}

.right-pane > .content {
  flex-grow: 1;
}
.right-pane > .footer {
  text-align: center;
  font-size: 12px;
  letter-spacing: 2px;
}

.right-pane > .footer > sup {
  letter-spacing: 0px;
}
/* home.jsx */

/* tabs.jsx */
.tabs {
  padding: 20px 0px;
  display: flex;
  font-size: 15px;
}

.tabs > a {
  color: black;
}

.a-tab {
  margin: 0 4px;
  margin-left: 0;
  font-weight: 100;
  padding: 8px 13px;
}

.a-tab:hover {
  background: rgba(220, 70, 65, 0.308);
  border-radius: 3px;
  cursor: pointer;
}

.active.a-tab {
  background: #dc4641;
  border-radius: 3px;
  color: white !important;
}

.active.a-tab:hover {
  background: #dc4641;
}
/* tabs.jsx */

/* userInfo.jsx */
.auth-component {
  display: flex;
}

.auth-username {
  flex-grow: 1;
}

.auth-menu {
  height: 35px;
  line-height: 35px;
  margin-left: 10px;
}

.auth-avatar {
  height: 35px;
  width: 35px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  border-radius: 5px;
  margin-left: auto;
}
/* userInfo.jsx */

/* setOrder.jsx */
.set-order-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dnd-body {
  flex-grow: 1;
  overflow-y: scroll;
}

.dnd-body::-webkit-scrollbar {
  display: none;
}

.footer-status-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 11.5px;
  line-height: 28px;
  height: 28px;
  background: black;
  color: rgb(189, 189, 189);
  padding: 0 30px;
  z-index: 100;
}

.footer-status-bar > * {
  cursor: pointer;
  padding: 0 5px;
}

.footer-status-bar > *:hover {
  background: rgb(31, 31, 31);
}

.footer-status-bar > * > .config-version {
  color: #22ba46;
}

/* setOrder.jsx */

/* Dnd.jsx */
.drop-container {
  display: flex;
}

.drop-left {
  width: 93%;
}

.drop-right {
  width: 7%;
}

.addRowIcon {
  position: relative;
  top: -20px !important;
}
/* Dnd.jsx */

/* Stream.jsx */
.hover-matrix {
  display: grid;
  grid-template-columns: 60px 60px auto auto 60px 60px;
  grid-column-gap: 20px;
  grid-row-gap: 15px;
}

.hover-matrix-cell {
  width: 100px;
}
/* .hover-matrix-cell > .title {
  font-weight: bold;
} */

.popup-disclaimer {
  margin-top: 15px;
  font-size: 10px;
  text-align: right;
}

.hover-matrix-cell > .data {
  font-size: 25px;
}
/* Stream.jsx */

/* StreamCardMatrix.jsx */
.hover-matrix-stream {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
}

.hover-matrix-stream-cell {
  width: 5px;
}
.hover-matrix-stream-cell > .title {
  font-size: 12px;
  color: black;
  opacity: 0.5;
}

.hover-matrix-stream-cell > .data {
  font-size: 17px;
}
/* StreamCardMatrix.jsx */

.deleteRow {
  min-height: 100%;
  min-width: 100%;
  color: #ff2e39;
  border-radius: 5px;
  border: 2px dashed rgba(255, 0, 0, 0.658);
  padding: 10px 0;
  text-align: center;
}

/* row.jsx */
.no-scroll::-webkit-scrollbar {
  display: none;
}

.row-id {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  text-align: right;
  width: 60px;
  font-weight: bold;
}

.add-stream {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-stream:hover {
  color: teal;
}
/* row.jsx */

/* PlaceholderImage.jsx */
.placeholder-div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* PlaceholderImage.jsx */

.streamFormBody {
  border-radius: 5px;
  padding: 45px;
  background: #e6f1ff;
}

.studio-body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  flex: 0 1 auto;
}

.dropdown-setorder {
  min-width: 10px !important;
}

.legend {
  margin-top: 2px;
  display: grid;
  grid-template-columns: auto auto;
  font-size: 80%;
}

.a-legend {
  display: flex;
  margin: 0 2px;
}

.color {
  height: 15px;
  width: 15px;
  border-radius: 3px;
}

.legend-name {
  line-height: 16px;
  margin: 0 4px;
}

.cards {
  padding: 0 15px;
  padding-top: 2px;
  flex: 1 1 auto;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 20px;
}

.stream-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cards::-webkit-scrollbar {
  display: none;
}

.ui.card > .content > .header {
  font-size: 110% !important;
  width: 100% !important;
  word-wrap: break-word !important;
}

.ui.card > .content > .description {
  word-wrap: break-word !important;
}

.ui.card {
  -webkit-transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
}

.ui.card:hover {
  border-left-width: 40px !important;
}

.extra-wrapper {
  display: flex;
  justify-content: space-around;
}

.ui.card.genesis {
  background: #00acc1;
}
.ui.card.genesis > .content {
  color: white !important;
}
.ui.card.genesis > .extra.content {
  display: inline-block;
}
.ui.card.genesis > .content > .header,
.ui.card.genesis > .content > .meta,
.ui.card.genesis > .content > .description {
  color: white !important;
}

.card-icon {
  cursor: pointer;
}

.new-stream:hover {
  color: #1b1b1b;
}

.edit:hover {
  color: #2962ff;
}

.fork:hover {
  color: #4caf50;
}

.delete:hover {
  color: #ff3d00;
}

.infobar {
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
}

.ui.label {
  font-weight: 100 !important;
  padding: 10px !important;
  color: black;
}

.ui.dropdown {
  width: 400px !important;
}

.swal-modal .swal-text {
  text-align: center !important;
}

.view-selection {
  width: 20px !important;
}

.overall-table {
  font-size: 15px;
}

.a-cell {
  height: 50px;
}

.a-cell-header {
  padding: 15px 0 !important;
}

.a-cell-data {
  font-size: 110%;
  font-weight: bold;
}

.ReactTable .rt-thead {
  overflow-y: scroll;
}

.overall-size-matrix {
  display: grid;
  color: black !important;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 25px;
  padding: 10px 0;
  margin: auto;
}

.overall-size-matrix-cell > .data {
  font-size: 35px;
  margin-bottom: 10px;
}

.count-metrics-cell {
  display: flex;
  font-size: 10pt;
}

.count-metrics-cell > .title {
  flex-grow: 1;
}

.subtitle {
  margin: 0;
  padding: 0;
  margin-top: 15px;
  font-weight: bold;
  color: #15ab39;
  text-transform: uppercase;
}

/* infomain.jsx */
.infomain-body {
  height: 82vh;
  margin-top: 20px;
  overflow-y: scroll;
  border-radius: 5px;
}

.infomain-body::-webkit-scrollbar {
  display: none;
}

.card-my {
  background: white;
  color: black;
  margin: 10px 0;
  border-radius: 5px;
  padding: 20px;
}
/* infomain.jsx */
.filter-body {
  height: 80vh;
  overflow-y: scroll;
}

.filter-body::-webkit-scrollbar {
  display: none;
}

.filter-heading {
  margin-bottom: 10px;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.filter-heading > p,
.filter-heading > h4 {
  padding: 0;
  margin: 0;
  text-transform: none;
}

.filter-heading > .subtitle {
  font-size: 13px;
  color: rgb(139, 139, 139);
}

.global-stats-matrix {
  display: grid;
  color: black !important;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 25px;
  padding: 10px 0;
  margin: auto;
}

.global-stats-matrix-cell {
  display: flex;
  font-size: 10pt;
  flex-direction: column;
}

.global-stats-matrix-cell > .data {
  font-size: 25px;
  margin-bottom: 2px;
}

.global-stats-matrix-cell > .title {
  flex-grow: 1;
}

.meta-data {
  /* background: red; */
}

.meta-data > .title {
  font-weight: bold;
  text-transform: capitalize;
}

.meta-data > .data {
  display: inline-block;
  padding: 0;
  margin: 5px;
}

.unauthorized {
  background: #db4640;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.unauthorized > .text {
  font-size: 15pt;
  color: white;
}

@font-face {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2)
      format("woff2");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Product Sans" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

